/* eslint-disable react/prop-types */
import {
  Card,
  Image,
  Flex,
  Text,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import { BsArrowUpRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import style from './style.module.css';

function ExperienceCard({ image, title, text, date, link }) {
  const navigate = useNavigate();
  const responsiveBool = useBreakpointValue({
    base: true,
    small: true,
    medium: false,
    large: false,
    xl: false,
    xxl: false,
  });

  const card = (
    <Card
      height={responsiveBool ? '100px' : '100px'}
      width={responsiveBool ? '250px' : '300px'}
      borderRadius="10px"
      padding="5px"
      backgroundColor="#262626"
      border="1px solid white"
      className={style.card}
      onClick={() => {
        window.scroll(0, 0);
        navigate('/working');
      }}
    >
      <Flex
        direction="row"
        width="100%"
        alignItems="center"
        height="100%"
        justifyContent="space-between"
        gap="0"
      >
        <Flex
          direction="column"
          width="30%"
          alignItems="center"
          height="100%"
          justifyContent="center"
        >
          <Image
            src={image}
            width="50px"
            height="50px"
            borderRadius="100px"
            backgroundColor="#ffffff"
          />
        </Flex>
        <Flex
          direction="column"
          color="black"
          height="100%"
          width="60%"
          alignItems="start"
          gap="0"
          justifyContent="center"
          marginLeft="20px"
        >
          <Text fontWeight="bold" color="#ffffff" padding="0">
            {title}
          </Text>
          <Text color="#ffffff" padding="0" fontWeight="normal" fontSize="12px">
            {text}
          </Text>
        </Flex>
        <Flex
          direction="column"
          color="black"
          height="100%"
          width="10%"
          alignItems="center"
          gap="0"
          justifyContent="center"
        >
          <BsArrowUpRight color="#ffffff" />
        </Flex>
      </Flex>
    </Card>
  );

  return card;
}

export default ExperienceCard;
