/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { FaSquareGithub, FaLinkedin } from 'react-icons/fa6';
import style from './style.module.css';

function Nav({ cut, icons }) {
  const navigate = useNavigate();
  function scrollToSection(sectionId) {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const responsiveBool = useBreakpointValue({
    base: true,
    small: true,
    medium: false,
    large: false,
    xl: false,
    xxl: false,
  });

  const leftSide = (
    <Flex width="100%" direction="row" gap="30px">
      <span
        className={style.link}
        onClick={() => {
          navigate('/');
        }}
      >
        home
      </span>
      {!cut && (
        <>
          <span
            className={style.link}
            onClick={() => {
              scrollToSection('#experience');
            }}
          >
            experience
          </span>
          <span
            className={style.link}
            onClick={() => {
              scrollToSection('#stats');
            }}
          >
            stats
          </span>
          <span
            className={style.link}
            onClick={() => {
              scrollToSection('#contact');
            }}
          >
            contact
          </span>
        </>
      )}
    </Flex>
  );

  const rightSide = (
    <Flex width="100%" direction="row" gap="30px" justifyContent="end">
      <FaSquareGithub
        onClick={() => {
          window.open('https://github.com/guillermo-avelar', '_blank');
        }}
        className={style.icon}
      />
      <FaLinkedin
        className={style.icon}
        onClick={() => {
          window.open(
            'https://www.linkedin.com/in/jose-guillermo-avelar/',
            '_blank'
          );
        }}
      />
    </Flex>
  );

  return (
    <Flex
      width={responsiveBool ? '100%' : '800px'}
      direction="row"
      justifyContent="space-between"
    >
      {leftSide}
      {rightSide}
    </Flex>
  );
}

export default Nav;
