/* eslint-disable import/no-extraneous-dependencies */
import './assets/styles/App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Wrong } from './pages/Wrong';
import { Working } from './pages/Working';
import { HabitatApp } from './components/experience/HabitatApp';
import { Model } from './components/experience/Model';
import { FTSL } from './components/experience/FTSL';
import { Picnic } from './components/experience/Picnic';
import { FitForMortgage } from './components/experience/FitForMortgage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="working" element={<Working />} />
      <Route path="/*" element={<Wrong />} />
    </Routes>
  );
}

export default App;
