import {
  Flex,
  Text,
  Image,
  Button,
  Heading,
  Divider,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { AutoPlay } from '@egjs/flicking-plugins';

// eslint-disable-next-line import/no-extraneous-dependencies
import Nav from '../../components/Nav';
import pro from '../../assets/images/pro.png';
import finca from '../../assets/images/finca-angulo.jpg';
import volcan from '../../assets/images/volcan.jpg';
import picnic from '../../assets/images/picnic.png';
import ftsl from '../../assets/images/ftsl.jpg';
import data from '../../assets/images/data.jpg';
import ExperienceCard from '../../components/ExperienceCard';
import style from './style.module.css';

const Home = () => {
  const responsiveBool = useBreakpointValue({
    base: true,
    small: true,
    medium: false,
    large: false,
    xl: false,
    xxl: false,
  });

  const home = (
    <Flex
      width={responsiveBool ? '100%' : '800px'}
      direction="column"
      gap="20px"
      color="#ffffff"
      id="home"
    >
      <Heading level="4" color="#ffffff">
        hey, I'm guillermo 👋
      </Heading>
      <Divider />
      <Text color="#ffffff" lineHeight="50px">
        I'm Guillermo, a product builder obsessed over creating intuitive and
        elegant solutions to complex problems. When I'm not coding, designing or
        crafting some ML model, I'm studying pure math at Columbia. By my
        ecclectic nature, I'm mostly around startups or highly innovative
        environments.
      </Text>
      <Flex direction="row" width="100%" justifyContent="center">
        <div className={style.container}>
          <Image
            src={pro}
            width="255px"
            height="369px"
            borderRadius="10px"
            className={style.img}
          />
          <div className={style.textOverlay}>a pic at columbia 🦁</div>
        </div>
        {!responsiveBool && (
          <Flex direction="column" height="100%">
            <div className={style.container} width="267px" height="200px">
              <Image
                src={volcan}
                width="267px"
                height="200px"
                borderRadius="10px"
                className={style.img}
              />
              <div className={style.textOverlay}>
                central america's tallest volcano 🌋
              </div>
            </div>
            <div className={style.container} width="270px" height="154px">
              <Image
                src={finca}
                width="270px"
                height="154px"
                borderRadius="10px"
                className={style.img}
              />
              <div className={style.textOverlay}>a farm in el salvador 🐄</div>
            </div>
          </Flex>
        )}
      </Flex>
    </Flex>
  );

  const plugins = [new AutoPlay()];

  const experience = (
    <Flex
      width={responsiveBool ? '100%' : '800px'}
      direction="column"
      gap="20px"
      color="#ffffff"
      id="experience"
    >
      <Heading level="4" color="#ffffff">
        my projects 🌎
      </Heading>
      <Divider />
      <Flex
        direction="row"
        gap="20px"
        width="100%"
        height="150px"
        justifyContent="center"
      >
        <Flicking
          circular
          plugins={plugins}
          bound
          align="center"
          panelsPerView={1}
          horizontal={false}
        >
          <div>
            <ExperienceCard
              image="https://public-bucket-hfh.s3.amazonaws.com/marketing/logo-full.png"
              title="Habitat App"
              text="Automating mortgage flows"
              link="habitatapp"
            />
          </div>
          <div>
            <ExperienceCard
              image={picnic}
              title="Picnic"
              text="GenZ interest communities"
              link="picnic"
            />
          </div>
          <div>
            <ExperienceCard
              image={data}
              title="AI/ML Models"
              text="Cat or dog?"
              link="model"
            />
          </div>
          <div>
            <ExperienceCard
              image={ftsl}
              title="FTSL"
              text="NGO for the deaf"
              link="ftsl"
            />
          </div>
        </Flicking>
      </Flex>
    </Flex>
  );

  const contact = (
    <Flex
      width={responsiveBool ? '100%' : '800px'}
      direction="column"
      gap="20px"
      color="#ffffff"
      id="contact"
    >
      <Heading level="4" color="#ffffff">
        contact 📞
      </Heading>
      <Divider />
      <Text color="#ffffff" lineHeight="50px">
        I am always open to new opportunities, collaborations and meeting new
        people. Feel free to reach out to me at{' '}
        <a href="mailto:jga2131@columbia.edu" className={style.link}>
          jga2131@columbia.edu
        </a>
      </Text>
    </Flex>
  );
  const newStats = (
    <Flex
      width={responsiveBool ? '100%' : '800px'}
      direction="column"
      gap="20px"
      color="#ffffff"
      id="stats"
    >
      <Heading level="4" color="#ffffff">
        quick stats 🗃️
      </Heading>
      <Divider />
      <Flex direction="column" gap="40px" width="100%" justifyContent="center">
        <Flex direction="column" width="100%" justifyContent="center" gap="5px">
          <Text color="#ffffff" width="100%" padding="0">
            columbia
          </Text>
          <Text color="#ffffff" width="100%" padding="0" fontWeight="thin">
            math major | stats minor | john jay scholar
          </Text>
        </Flex>
        <Flex direction="column" width="100%" justifyContent="center" gap="5px">
          <Text color="#ffffff" width="100%" padding="0">
            languages
          </Text>
          <Text color="#ffffff" width="100%" padding="0" fontWeight="thin">
            javascript | typescript | python | java | R | html | css
          </Text>
        </Flex>
        <Flex direction="column" width="100%" justifyContent="center" gap="5px">
          <Text color="#ffffff" width="100%" padding="0">
            libraries
          </Text>
          <Text color="#ffffff" width="100%" padding="0" fontWeight="thin">
            react | node | pandas | numpy | sklearn | django
          </Text>
        </Flex>
        <Flex direction="column" width="100%" justifyContent="center" gap="5px">
          <Text color="#ffffff" width="100%" padding="0">
            other random tech skills
          </Text>
          <Text color="#ffffff" width="100%" padding="0" fontWeight="thin">
            aws | figma | git | bash
          </Text>
        </Flex>
        <Flex direction="column" width="100%" justifyContent="center" gap="5px">
          <Text color="#ffffff" width="100%" padding="0">
            interests
          </Text>
          <Text color="#ffffff" width="100%" padding="0" fontWeight="thin">
            progressive rock | Spanish guitar | sci-fi novels | buffalo wings |
            soccer | hiking
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
  return (
    <Flex
      width="100%"
      justifyContent="center"
      padding="50px"
      direction="column"
      alignItems="center"
      gap="100px"
    >
      <Nav cut={responsiveBool} icons={responsiveBool} />
      {home}
      {experience}
      {newStats}
      {contact}
    </Flex>
  );
};

export default Home;
